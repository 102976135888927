
import { Component, Vue } from 'vue-property-decorator';

import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Clause } from '../../entities/clause.entity';
import ClauseService from '../../services/clause.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class ClauseListComponent extends Vue {
  private clauses: Clause[] = [];

  private get headers(): any {
    const items = [
      { text: 'Descrição', value: 'description', width: '50%' },
      {
        text: 'Juros',
        value: 'interestRateFormatted',
      },
      {
        text: 'Ativo',
        value: 'activeText',
      },

      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(clause: Clause) {
    this.$router.push({
      name: 'clause-edit',
      params: {
        id: clause.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'clause-create',
    });
  }

  private async getClauses() {
    const clauses = await ClauseService.getAllClausesPaginated();
    this.clauses = clauses.map((clause: Clause) => new Clause(clause));
  }

  public async backPagination() {
    if (!ClauseService.simplePaginationService.prevLink) return;
    const clauses = await ClauseService.simplePaginationService.prev();
    this.clauses = clauses.map((clause: Clause) => new Clause(clause));
  }

  public async nextPagination() {
    if (!ClauseService.simplePaginationService.nextLink) return;
    const clauses = await ClauseService.simplePaginationService.next();
    this.clauses = clauses.map((clause: Clause) => new Clause(clause));
  }

  public async goToFirst() {
    if (!ClauseService.simplePaginationService.firstLink) return;
    const clauses = await ClauseService.simplePaginationService.goToFirst();
    this.clauses = clauses.map((clause: Clause) => new Clause(clause));
  }

  private async created() {
    this.getClauses();
  }
}
