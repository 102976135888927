import store from '@/store';
import ClauseService from '../services/clause.service';

export class Clause {
  public id: string = '';

  public description: string = '';

  public active: boolean | number = false;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public company: any;

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
    };
  }

  public save(): any {
    return ClauseService.create(this.format());
  }

  public update(): any {
    return ClauseService.update(this.format());
  }

  public delete(): any {
    return ClauseService.delete(this.id);
  }
}
